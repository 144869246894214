import Head from "next/head";
import Container from "../components/Container2";
import Layout from "../components/Layout";
import { GetStaticProps } from "next";
import { getHomepageSeo } from "@/lib/seo";
import MetaData from "@/components/MetaData3";

export default function Page404({ ...props }) {

  return (
    <Layout>
      <MetaData {...props} />
      <Head>
        <title>Numa - Page not Found</title>
      </Head>
      <Container>
        <div className="h-full flex items-center justify-center min-h-[50vh]">
          <div className="text-center">
            <h3 className="mb-2 text-4xl lg:text-3xl font-bold lowercase">
              404 Error
            </h3>
            <span className="text-xl">{"The page you are looking for can't be found."}</span>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export const getStaticProps: GetStaticProps = async ({ params }) => {
  const homepageSeo = await getHomepageSeo();

  return {
    props: {
      seoData: homepageSeo,
    },
    revalidate: 1,
  };
};